.about {
  @media only all and (min-width: 920px) {
    display: flex;
  }
  .section1 {
    font-size: 25px;
    font-weight: bold;
    box-shadow: 0px 10px 100px -43px rgba(66, 68, 90, 1);
    border-radius: 50px;
    max-width: 380px;
    padding: 25px;
    margin: 15px auto;
    @media only all and (min-width: 920px) {
      max-width: 800px;
      font-size: 18px;
    }
    img {
      width: 55px;
    }
    li {
      display: flex;
      margin-block: 15px;
      align-items: center;
    }
    a {
      color: black;
    }
    h2 {
      background-color: #fce30091;
      border-radius: 30px;
    }
  }
  .section2 {
    font-size: 25px;
    font-weight: bold;
    box-shadow: 0px 10px 100px -43px rgba(66, 68, 90, 1);
    border-radius: 50px;
    max-width: 380px;
    padding: 25px;
    margin: 15px auto;
    @media only all and (min-width: 920px) {
      max-width: 800px;
      font-size: 18px;
    }
    h2 {
      background-color: #fce30091;
      border-radius: 30px;
    }
    h4 {
      padding: 20px;
    }

    .wrapper {
      @media only all and (min-width: 920px) {
        display: flex;
      }
      .mapContainer {
        min-width: 300px;
      }
    }
  }
}
