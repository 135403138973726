.TopViews {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 4px;
  img {
    width: 100%;
  }

  .wrapper {
    position: absolute;
    z-index: 1;
    max-width: 420px;
    font-size: 18px;
    top: 200px;
    text-align: left;
    .linkWrapper {
      display: flex;
      align-items: center;
      border-width: 1px;
      text-shadow: 6px 2px 4px rgba(0, 0, 0, 1);
      a {
        text-decoration: none;
        color: #ebff07;
        font-size: 35px;
      }
      img {
        width: 35px;
      }
      @media only all and (max-width: 720px) {
        display: none;
      }
    }
    h1 {
      color: white;
      margin: 0;
      font-size: 38px;
      @media only all and (max-width: 720px) {
        text-shadow: 4px 4px 8px rgba(136, 136, 136, 1);
      }
      @media only all and (max-width: 400px) {
        font-size: 30px;
      }
      @media only all and (min-width: 1200px) {
        font-size: 60px;
      }
    }
    h2 {
      color: #ebff07;
      margin: 0;
      font-size: 26px;
      @media only all and (max-width: 400px) {
        font-size: 24px;
      }
      @media only all and (min-width: 1200px) {
        font-size: 40px;
      }
    }
    h2 span {
      background: black;
    }
    h3 {
      color: white;
      margin: 0;
      font-size: 22px;

      @media only all and (max-width: 400px) {
        font-size: 22px;
      }
      @media only all and (min-width: 1200px) {
        font-size: 30px;
      }
    }
    @media only all and (max-width: 720px) {
      max-width: 300px;
      top: 180px;
    }
    @media only all and (max-width: 400px) {
      max-width: 250px;
      top: 120px;
    }
    @media only all and (min-width: 1200px) {
      max-width: 700px;
    }
  }
}
.imgDesktop {
  @media only all and (max-width: 720px) {
    display: none;
  }
}
.imgMobile {
  @media only all and (min-width: 720px) {
    display: none;
  }
}
.imgDesktopWrapper {
  position: relative;
  &:before {
    position: absolute;
    inset: 0;
    content: "";
    background: linear-gradient(
      345.66deg,
      rgba(30, 35, 41, 0.74) 29.55%,
      rgba(0, 0, 0, 0) 131.08%
    );
  }
}
.imgMobileWrapper {
  position: relative;
  &:before {
    position: absolute;
    inset: 0;
    content: "";
    background: linear-gradient(
      345.66deg,
      rgba(30, 35, 41, 0.74) 29.55%,
      rgba(0, 0, 0, 0) 131.08%
    );
  }
}
