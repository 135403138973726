.dots {
  display: flex;
  position: absolute;
  justify-content: center;
  width: 100%;
  margin-top: -35px;
  padding: 0;

  li {
    &:global(.slick-active) {
      button {
        opacity: 1;
      }
    }
    button {
      color: transparent;
      background-color: #fce300;
      border-radius: 50%;
      border: none;
      opacity: 0.5;
      cursor: pointer;
      margin-inline: 7px;
      height: 12px;
      width: 12px;
    }
  }
}
.tile {
  position: relative;
  img {
    width: 100%;
  }
  .butt {
    position: absolute;
    z-index: 5;
    margin-top: -85px;
    width: 100%;
    @media only all and (min-width: 720px) {
      margin-top: -55px;
    }
    button {
      z-index: 5;
      border: 5px;
      background-color: #fce300;
      border-radius: 10px;
      height: 45px;
      margin-left: auto;
      margin-right: auto;
      font-size: 25px;
      font-weight: bold;
      padding: 0px 15px;
      cursor: pointer;
    }
  }
  a {
    display: block;
  }
  .overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 54%,
      rgba(0, 0, 0, 1) 100%
    );
  }
}
ul {
  list-style: none;
}
