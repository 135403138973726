.NavMobile {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  z-index: 1001;
  top: 70px;
  background-color: rgb(37, 37, 37);

  a {
    z-index: 2;
    color: white;
    font-size: 40px;
    margin-bottom: 30px;
    opacity: 0.9;
    position: relative;
    top: -90px;
  }
}
