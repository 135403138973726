.Footer {
  background-color: #303030;
  color: white;
  display: flex;
  justify-content: center;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 100%;

  .wrapper {
    @media only all and (min-width: 720px) {
      display: flex;
    }
    .wrapperData {
      @media only all and (min-width: 720px) {
        margin-right: 40px;
      }
    }
  }
  .wrapperPhone {
    h2 {
      margin: 0px;
      padding-left: 20px;
    }
  }
  img {
    width: 45px;
  }

  h2 {
    margin: 0px;
  }
  ul {
    list-style: none;
    padding: 0;
  }

  p {
    max-width: 200px;
  }
  .footerPhone {
    li {
      display: flex;
      margin: 15px;
      align-items: center;
    }

    img {
      width: 35px;
    }
    a {
      color: white;
    }
  }
}
