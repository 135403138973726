.NavDeskopt {
  display: flex;
  background: rgb(0, 0, 0);
  height: 100%;
  a {
    font-size: 20px;
    height: 50px;
    margin-inline: 30px;
    height: 100%;
    color: white;
    display: flex;
    align-items: center;
  }
  a:hover {
    text-decoration: underline;
  }
  .selected {
    background: #fce300;
    padding-inline: 15px;
    color: black;
  }
}
