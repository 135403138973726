.section3 {
  box-shadow: 0px 10px 100px -43px rgba(66, 68, 90, 1);
  border-radius: 50px;
  max-width: 380px;
  padding: 5px 25px;
  margin: 15px auto;
  .tile {
    position: relative;
    max-height: 280px;
    img {
      width: 100%;
    }
    .text {
      position: absolute;
      z-index: 5;
      margin-top: -65px;
      width: 100%;
      p {
        z-index: 5;
        font-size: 25px;
        color: white;
      }
    }

    .overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      margin-top: 5px;
      background-color: rgba(0, 0, 0, 0);
      background-image: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0) 54%,
        rgba(0, 0, 0, 1) 100%
      );
    }
  }
  ul {
    list-style: none;
  }
}
